.pag-dinamica {
    font-size: 1.3rem;
    h1.pag-titulo {
        text-align: center;
    }
    a {
        font-size: 1.3rem;
    }
    pre{
        font-size: 1rem;
        color: $text-color;
    }
    img{
        max-width: 100%;
        margin: 1rem auto 2.5rem;
        overflow: hidden;
        margin-left: 1rem;
        @include border-radius(0.7rem);
        &.promociones {
            max-height: 40rem;
            max-width: 50rem;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
        &.destacada {
            @include box-shadow(0.5rem, 0.5rem, 1.2rem, $box-shadow-light);
            @include border-radius(0.7rem);
            margin-left: 0;
        }
    }
    ul {
        list-style: none;

        li::before {
            content: "\2022";
            color: $primary-blue;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
    .address{
        font-style: italic;
    }
    p img {
        margin-bottom:1.5rem;
    }
    table {
        img {
            margin-bottom: 1rem;
        }
        p img {
            margin-bottom:1rem;
        }
    }
    .tabla-consulta {
        p {
            padding-top: 2%;
        }
    }
    &.compra_online {
        img {
            margin: 1rem;
            &.imagen-titulo {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                margin-bottom: 2rem;
                margin-top: 2rem;
            }
        }
        .color-blanco {
            color: $primary-grey-light;
        }
        .color-naranja {
            color: $primary-orange;
        }
        .color-azul {
            color: $primary-blue;
        }
        .color-verde {
            color: $primary-green;
        }
        .subtitulo-texto {
            font-size: 1.6rem;
        }
        .subtitulo-fondo {
            font-weight: bold;
            text-align: center;
            font-size: 1.6rem;
            @include box-shadow(0.5rem, 0.5rem, 1.2rem, $box-shadow-light);
            @include border-radius(0.7rem);
            &.color-blanco {
                color: $primary-blue;
                text-shadow: 1px 0 $primary-blue;
                background-color: $primary-grey-light;
            }
            &.color-naranja {
                color: $primary-blue;
                text-shadow: 1px 0 $primary-blue;
                background-color: $primary-orange;
            }
            &.color-azul {
                color: $primary-grey-light;
                text-shadow: 1px 0 $primary-grey-light;
                background-color: $primary-blue;
            }
            &.color-verde {
                color: $primary-grey-light;
                text-shadow: 1px 0 $primary-grey-light;
                background-color: $primary-green;
            }
        }
        table {
            margin-top: 2rem !important;
            margin-bottom: 2rem !important;
            ul.dtr-details {
                list-style: none;
                li::before {
                    content: "";
                }
            }
            span.dtr-data {
                li::before {
                    content: "\2022";
                    color: $primary-blue;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
            .texto-largo {
                padding: 0 !important;
                p {
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                }
            }
        }

    }
    .iframe-recarga {
        width: 60%;
        height: 50rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        #ModalRecarga {
            background-color: white;
        }
    }
    .iframe-pagoservicios {
        width: 100%;
        min-height: 200rem;
    }
}
.back-novedades {
    background: $bg-back-novedades;
}
