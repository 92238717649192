.card {
    @include border-radius(2rem);
    background-color: $primary-green;
    .card-header {
        color: $white;
        text-align: center;
        //font-weight: bold;
        font-size: 1.6rem;
        font-weight:map-get($font-weight,bold);
        text-shadow: 1px 0 $white;
        line-height: 0.6;
        margin-top: 0;
    }
    .card-body {
        padding: 0;
        img {
            max-width: 100%;
        }
    }
    &.card-formulario {
        margin-top: 4rem;
        .card-body {
            background-color: $primary-grey-light-light;
            padding: 1.25rem;
            @include border-radius(2rem);
            padding-bottom: 4rem;
        }
    }
}