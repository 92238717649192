.form-solicitud-home {
    position: relative;
    z-index: 1030;
    //@include box-shadow(0rem, 0.5rem, 1.5rem, $header-shadow);
    #form_tarjeta {
        width: 35%;
        margin-left: auto;
        margin-right: auto;
        //margin: 2rem;
        background-color: $primary-grey-light;
        border-radius: 3rem;
        position: relative;
        top: -.5rem;
        @include respond-to ('tablet') {
            width: 60%;
        }
        @include respond-to ('old_desktop') {
            width: 40%;
        }
        @include respond-to ('wide') {
            width: 35%;
        }
        div.form-group {
            margin: 0;
            padding: .1rem;
        }
        input {
            border-radius: 2rem;
            margin-left: 0.6rem;
        }
        button {
            @include border-radius(2rem);
            //padding-right: 2rem;
            text-align: center;
            //background: $primary-orange;
            //border-color: $primary-orange;
            padding: 0rem 0.5rem 0rem 0rem;
            @include gradient($primary-blue, $secondary-blue);
            color: $primary-grey-light;
            -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
            -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            //@include box-shadow-2(1rem, 0rem, 1.5rem, $box-shadow, false);
            width: 100%;
            height: 4rem;
            font-size: 1.4rem;
        }
    }
}
.imagen-tienda {
    img {
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        border-radius: 1rem;
        width: 100%;
    }
}
.bloques-home {
    .card-home {
        img {
            @include border-radius-partial(0,0,2rem,2rem);
        }
        .card-header {
            line-height: 1;
            @include respond-to ('tablet') {
                font-size: 1rem;
            }
            @include respond-to ('old_desktop') {
                font-size: 1.4rem;
            }
            @include respond-to ('wide') {
                font-size: 1.4rem;
            }
        }
    }
    a {
        display: flex;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
        margin-top: -1rem;
        z-index: 1050;
        position: relative;
        font-size: 1.4rem;
        padding-top: 0.6rem;
    }
    a.ver-mas {
        width: 50%;
        @include gradient($primary-grey-light, $primary-grey-light-light);
        background-color: $primary-grey-light-light;
        color: $primary-blue;
        @include respond-to ('tablet') {
            font-size: 1.2rem;
            padding-top: .6rem;
        }
        @include respond-to ('old_desktop') {
            font-size: 1.4rem;
            padding-top: .6rem;
        }
        @include respond-to ('wide') {
            font-size: 1.6rem;
            padding-top: .4rem;
        }
    }
    &.comercio {
        a {
            display: grid;
            width: 100%;
            margin-top: -.5rem;
            text-align: center;
            p {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-right: -50%;
                transform: translate(-50%, -50%);
                color: $white;
                font-weight:map-get($font-weight,bold);
                text-shadow: 1px 0 $white;
            }
        }
        .card {
            background-color: $primary-blue;
            //img {
            //    opacity: 0.7;
            //}
        }
    }
}
.promo-destacada {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
    .row {
        margin: 0;
        .lado-texto {
            width: 10%;
            position: absolute;
            top: 0;
            bottom: 0;
            padding: 0;
            z-index: 1030;
            background-color: $primary-green;
            margin-top: .2rem;
            margin-bottom: .2rem;
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
            @include border-radius-partial(0,4rem,4rem,0);
            h2 {
                color: $white;
                position: absolute;
                left: 50%;
                top: 40%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                font-weight:map-get($font-weight,bold);
                text-shadow: 1px 0 $white;
                text-align: center;
                @include respond-to ('tablet') {
                    font-size: 1rem;
                }
                @include respond-to ('old_desktop') {
                    font-size: 1.4rem;
                }
                @include respond-to ('wide') {
                    font-size: 1.6rem;
                }
                a {
                    padding: 0.7rem;
                    width: 100%;
                    margin-top: 2rem;
                    background-color: $primary-grey-light-light;
                    color: $primary-blue;
                    font-size: 1.2rem;
                    @include gradient($primary-grey-light, $primary-grey-light-light);
                    @include respond-to ('tablet') {
                        font-size: 1rem;
                    }
                    @include respond-to ('old_desktop') {
                        font-size: 1.2rem;
                    }
                    @include respond-to ('wide') {
                        font-size: 1.4rem;
                    }
                }
            }

        }
        .lado-imagen {
            padding: 0;
            z-index: 1020;
        }
    }
    img {
        //left: -4rem;
        position: relative;
        width: 100%;
    }
    &.comercio {
        p {
            position: absolute;
            z-index: 1060;
            font-size: 2rem;
            color: $white;
            font-weight:map-get($font-weight,bold);
            text-shadow: 1px 0 $white;
            bottom: 20%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }
        a.btn-verde {
            position: absolute;
            z-index: 1060;
            bottom: 5%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }
    }
}

.botones-descuentos {
    h2 {
        color: $primary-grey-light-light;
        font-weight: bold;
    }
    h3 {
        color: $primary-blue;
        font-weight: bold;
        text-align: center;
        font-size: 1.2rem;
        margin: 1rem;
        text-shadow: 1px $primary-blue;
    }
    .card {
        background-color: $primary-grey-light-light;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 12rem;
        height: 12rem;
        display: table;
        margin-right: 2rem;
        img {
            width: 30%;
            display: block;
            margin: 1rem auto;
        }
        .card-body {
            display: table-cell;
            vertical-align: middle;
            .card-title {
                font-family: $font-type-coolvetica;
            }
        }
        &.active {
            transform: scale(1.08);
            border: .3rem solid $primary-green-dark;
        }
    }
    a {
        &:hover {
            text-decoration: none;
            transform: scale(1.05);
        }
    }
}
