button, a.btn {
    color: $primary-orange;
    border: none;
    @include border-radius($border-radius);
    //text-transform: uppercase;
    height: $height-cta;
    &.btn{
        border: none;
    }

    &.btn-primary{
        @include border-radius(2rem);
        @include gradient($button-color-1, $button-color-2 );
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        @include respond-to ('tablet') {
            width: 80%;
        }
        @include respond-to ('old_desktop') {
            width: 50%;
        }
        @include respond-to ('wide') {
            width: 40%;
        }
        display: block;
        margin-right: auto;
        margin-left: auto;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        font-size: 1.6rem;
        padding-top: 0.4rem;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-orange;
            outline: none;
        }
    }

    &.btn-primary-blue{
        @include border-radius(2rem);
        @include gradient($secondary-blue, $primary-blue);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        @include respond-to ('tablet') {
            width: 80%;
        }
        @include respond-to ('old_desktop') {
            width: 50%;
        }
        @include respond-to ('wide') {
            width: 40%;
        }
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-top: 3rem;
        margin-bottom: 2rem;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        font-size: 1.6rem;
        padding-top: 0.4rem;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $secondary-blue;
            outline: none;
            color: $white;
        }
        &.login {
            width: 40%;
        }
        &.autogestion {
            width: 80%;
            font-size: 1.4rem;
        }
    }

    &.btn-blanco {
        color: $primary-grey-light;
    }

    &.btn-azul {
        color: $primary-blue;
    }

    &.btn-verde {
        @include border-radius(2rem);
        @include gradient($primary-green, $primary-green-light);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 30%;
        display: inline-block;
        //margin-right: auto;
        //margin-left: auto;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        font-size: 1.6rem;
        padding-top: 0.4rem;
        margin-top: 2rem;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $secondary-blue;
            outline: none;
            color: $white;
        }
        &.login-anterior {
            width: 60%;
        }
    }

    &.btn-anterior {
        @include border-radius(2rem);
        @include gradient($secondary-blue, $secondary-blue-ligth);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 30%;
        display: inline-block;
        //margin-right: auto;
        //margin-left: auto;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        font-size: 1.6rem;
        padding-top: 0.4rem;
        margin-top: 2rem;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $secondary-blue;
            outline: none;
            color: $white;
        }
        &.login-anterior {
            width: 60%;
        }
    }
    &.btn-siguiente {
        @include border-radius(2rem);
        @include gradient($secondary-blue, $secondary-blue-ligth);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 30%;
        display: inline-block;
        //margin-right: auto;
        //margin-left: auto;
        color: $primary-grey-light;
        margin-left: 20%;
        font-family: $font-type-coolvetica;
        font-size: 1.6rem;
        right: 0;
        position: absolute;
        margin-top: 2rem;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $secondary-blue;
            outline: none;
            color: $white;
        }
        &.login-siguiente {
            width: 50%;
            padding-top: 0.4rem;
        }
    }
    &.btn-izquierdo {
        @include border-radius(2rem);
        @include gradient($button-color-1, $button-color-2 );
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        display: inline-block;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-orange;
            outline: none;
        }
    }
    &.btn-derecho {
        @include border-radius(2rem);
        @include gradient($secondary-blue, $secondary-blue-ligth);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        display: inline-block;
        color: $primary-grey-light;
        vertical-align: top !important;
        position: relative;
        float: right;
        font-family: $font-type-coolvetica;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-blue;
            outline: none;
        }
    }
    &.volver-atras {
        padding-top: 0.4rem;
    }
    &.btn-outline-primary {
        border: .1rem solid $primary-green;
        @include border-radius(1rem);
        @include box-shadow(0.3rem, 0.3rem, 1rem, $header-shadow);
        font-weight: bold;
        color: $primary-green;
        font-family: $font-type-coolvetica;
        display: table;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.2rem;
        padding-top: 0.6rem;
        &:focus, &:hover, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-green;
            outline: none;
            color: $bg-color;
        }
    }
}

a.btn {
    padding-top: 0.8rem;
}

.btn-home {
    padding: 0.5rem;
    margin-top: 2rem;
    background-color: $primary-grey-light-light;
    color: $primary-blue;
    height: $height-cta;
    display: block;
    margin-right: auto;
    margin-left: auto;
    font-family: $font-type-coolvetica;
    font-size: 1.6rem;
    text-align: center;
    @include border-radius(2rem);
    @include gradient($primary-grey-light, $primary-grey-light-light);
    @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
    @include respond-to ('tablet') {
        width: 80%;
    }
    @include respond-to ('old_desktop') {
        width: 50%;
    }
    @include respond-to ('wide') {
        width: 40%;
    }
    &:focus, &:hover, &:active, &:not(:disabled):not(.disabled):active {
        background-color: $primary-blue;
        outline: none;
        text-decoration: none;
    }
}

.boton-anterior {
    svg {
        color: $white;
        font-size: 1.6rem;
    }
}
