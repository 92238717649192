
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass desktop slider.
// -----------------------------------------------------------------------------
.slider-home {
        &.container {
            //@include respond-to ('<wide_slider') {
            //    width:100%;
            //    max-width:100%;
            //    padding-left:0px;
            //    padding-right: 0px;
            //}
        }
    //@include respond-to ('<old_desktop'){
    //    width:100%;
    //    padding-left: 0;
    //    padding-right: 0;
    //}
    //@include respond-to ('old_desktop'){
    //    width:100%;
    //    padding-left: 0;
    //    padding-right: 0;
    //}
}

.slider-home-container {
    position: relative;
    //width:100%;
    display: block;
    padding-top:2rem;
    //@include respond-to ('wide_slider') {
    //    padding-top:2rem;
    //}
}
#slider-home{
    overflow: hidden;
    @include border-radius(0.8rem);
    height:30rem;
    @include box-shadow(0rem, 0.5rem, 1.5rem, $slider-shadow);
    @include respond-to ('<old_desktop') {
        height: 20rem;
    }
    @include respond-to ('old_desktop') {
        height: 26rem;
    }
    @include respond-to ('pre_wide') {
        height: auto;
    }
    @include respond-to ('wide_slider') {
        @include border-radius(0.8rem);
        height:30rem;
    }
    .carousel-inner {
        @include respond-to ('wide_slider') {
            height:30rem;
        }
    }
    .carousel-control-prev, .carousel-control-next{
        opacity: .75;
        height: 100%;
        width: 5%;
    }
    ol.carousel-indicators {
        margin: 0;
    }
    .carousel-indicators li {
        width: 0.9rem;
        height: 0.9rem;
        border: .5rem solid transparent;
        border-radius: 25px;
        margin: .8rem .1rem;
    }
    .carousel-item {
        //@include respond-to ('<old_desktop'){
        //    width:100%;
        //}
        img {
            @include respond-to ('tablet') {
                position: relative;
                height:20rem;
            }
            @include respond-to ('<old_desktop') {
                height:26rem;
                //max-width:none!important;
            }
            @include respond-to ('old_desktop') {
                height:28rem;
                //max-width:none!important;
                position: relative;
            }
            @include respond-to ('pre_wide') {
                height: 30rem;
                //width:100%;
                //max-width:100%;
                position: relative;
            }
            @include respond-to ('wide') {
                left: 0;
                height: 30rem;
            }
            @include respond-to ('wide_slider') {
                height: 30rem;
            }
        }

    }
}