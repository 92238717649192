.wrapper-autog {
    background-color: $primary-grey;
}
.menu-autogestion {
    background-color: $primary-blue;
    @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
    @include border-radius-partial(0,2rem,2rem,0);
    //width: 85%;
    position: fixed;
    height: 70%;
    width: 8rem;
    z-index: 1040;
    margin-top: 2rem;
    img {
        width: 35%;
    }
    a.link-menu {
        color: $white;
        font-size: 1rem;
        @include respond-to ('old_desktop') {
            font-size: 1rem;
        }
    }
    .img-home {
        width: 2rem;
        margin-top: 0;
    }
}

img.fondo-autogestion {
    max-width: 100%;
    z-index: 1010;
    //margin-top: -9rem;
    position: relative;
    //margin-top: 2rem;
    height: 100vh;
    width: 100%;
}
.dashboard-autogestion {
    //background: url('../uploads/images/iconos/autogestion/fondo-dashboard.jpg');
    position: relative;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    padding-top: 5rem;
    z-index: 1020;
    margin-right: 0;
    margin-left: 0;
    background-color: $primary-grey;
    min-height: 100vh;
    &.acciones-autogestion {
        position: absolute;
        top: 5rem;
        padding-top: 0;
        .cuerpo-acciones {
            margin-top: 2rem;
            img.img-cadadia {
                width: 100%;
                margin-bottom: 1rem;
                @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
                @include border-radius(.5rem);
            }
        }
    }
    &.formulario-autogestion {
        //background-color: $primary-grey-light;
        .form-check-inline {
            margin-left: 2rem;
        }
    }
    h1 {
        position: relative;
    }
    a {
        color: $primary-blue;
    }
    .card {
        border: none;
        .card-header {
            @include border-radius-partial(2rem,2rem,0,0);
            background-color: $primary-blue;
            line-height: 0.6;
            margin-top: 0;
            &.card-gestion {
                line-height: 1.2;
            }
        }
        .card-body {
            //padding-top: 0.4rem;
            //padding-bottom: 0.6rem;
            background-color: $primary-grey-light-light;
            @include border-radius-partial(0,0,2rem,2rem);
            h5.card-title {
                margin-bottom: 0.4rem;
                text-align: center;
                margin-top: 1rem;
                font-size: 2rem;
            }
            p.card-text {
                padding: 1rem;
                font-size: 1.2rem;
                span.resaltado {
                    font-size: 1.8rem;
                    font-weight: bold;
                }
                &.color-verde {
                    color: $primary-green;
                }
                &.color-azul {
                    color: $primary-blue;
                }
                &.color-rojo {
                    color: $brand-color;
                }
                &.color-gris {
                    color: $primary-grey;
                }
                &.dasboard {
                    font-size: 1rem;
                }
                &.info-pago {
                    position: absolute;
                    right: 2rem;
                    top: 2rem;
                    a {
                        font-size: 2rem;
                    }
                }
                &.info-pagocorazon {
                    animation: latidos .8s infinite;
                    position: absolute;
                    right: 2rem;
                    top: 2rem;
                    a {
                        font-size: 1.6rem;
                    }
                }
                a {
                    &.color-verde {
                        color: $primary-green;
                    }
                    &.color-azul {
                        color: $primary-blue;
                    }
                    &.color-rojo {
                        color: $brand-color;
                    }
                    &.color-gris {
                        color: $primary-grey;
                    }
                }
            }
        }
        &.card-formulario {
            margin-bottom: 8rem !important;
            margin-top: 2rem;
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
            @include border-radius-partial(2rem,2rem,2rem,2rem);
            button {
                margin-bottom: 1rem;
            }
            h1 {
                @include border-radius-partial(2rem,2rem,0,0);
                background-color: $primary-blue;
            }
            form {
                margin: 2rem;
            }
            .alert {
                margin-bottom: 0;
                margin-top: 0;
                width: 100%;
            }
            .form_dinamico .form-group {
                width: 100%;
            }
        }
        &.card-acciones {
            width: 100%;
            height: 6rem;
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
            .card-body {
                @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
                background: $light-base;
                display: flex;
                align-items: center;

                .card-title {
                    font-size: 1.4rem;
                    text-align: center;
                    font-weight: bold;
                    color: $primary-blue;
                    margin-left: 4rem;
                }

                .card-text {
                    color: $primary-blue;
                }

                img {
                    width: 3rem;
                    //margin-top: 1rem;
                    //margin-bottom: 1rem;
                    margin-right: 1rem;
                }
            }
        }
        &.card-orange {
            .card-body {
                @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
                background: $secondary-blue-ligth;
                height: 10rem;
                .card-title {
                    font-size: 1.8rem;
                    text-align: center;
                    font-weight: bold;
                    color: $white;
                }
                .card-text {
                    color: $white;
                }
            }
        }
    }
    .contenedor-card {
        //margin: 0 auto;
        //width: 14rem;
        a {
            //display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
        //height: 14rem;
        //margin-top: 2rem;
    }
    .cuadro-debito {
        @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
        border: 1px $primary-grey-dark solid;
        margin-bottom: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        h3 {
            font-size: 1.2rem;
            text-align: center;
            font-weight: bold;
            color: $primary-blue;
            //margin: 0 auto;
        }
    }
    .select2-container {
        width: 100% !important;
    }
}

.servicios-recomendados {
    .card {
        h3 {
            margin-top: 1rem;
        }
        .card-body {
            padding-left: 0;
        }
    }
}
img.logo-debito {
    width: 100px;
    height: 80px;
    margin-left: .6rem;
    padding: 0.2rem;
}

.div-terminos{
    height: 20rem;
    overflow-y: scroll;
    width:100%;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.pagar-autogestion {
    .iframe-pagoservicios {
        width: 100%;
        min-height: 200rem;
    }
}

@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.2); }
    to { transform: none; }
}
