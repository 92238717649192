.contenedor-contador {
    position: absolute;
    z-index: 1010;
    top: 0;
    &.promo-det {
        position: relative;
        top: 0;
        border: 1px solid $primary-blue;
        margin-left: auto;
        margin-right: auto;
    }
    .div-contador {
        position: absolute;
        top: 0rem;
        left: 33%;
        &.centro-izquierda {
            left: 1rem;
        }
        &.centro-derecha {
            right: 2rem;
            left: auto;
        }
    }
}

#contador_tiempo {
    position: relative;
    z-index: 1010;
    top: 11rem;
    right: 0;

    &.promo-det {
        position: relative;
        top: 0;
        border: 1px solid $primary-blue;
        padding: 1rem 0 0 0;
        margin-left: auto;
        margin-right: auto;
    }
    span {
        z-index: 1010;
        position: relative;
        color: $primary-blue;
        &.color-verde {
            color: $primary-green;
        }
        &.color-naranja {
            color: $primary-orange;
        }
        &.color-blanco {
            color: $primary-grey-light-light;
        }
        @include respond-to('<old_desktop') {
            font-size: 2rem;
        }
        @include respond-to('old_desktop') {
            font-size: 2.5rem;
        }
        @include respond-to('pre_wide') {
            font-size: 3rem;
        }
        @include respond-to('wide_slider') {
            font-size: 4rem;
        }

        &.texto-contador {
            color: $primary-blue;
            &.color-verde {
                color: $primary-green;
            }
            &.color-naranja {
                color: $primary-orange;
            }
            &.color-blanco {
                color: $primary-grey-light-light;
            }
            display: block;
            @include respond-to('<old_desktop') {
                margin-bottom: -1rem;
                font-size: 1.4rem;
            }
            @include respond-to('old_desktop') {
                margin-bottom: -1rem;
                font-size: 1.3rem;
            }
            @include respond-to('pre_wide') {
                margin-bottom: -1rem;
                font-size: 1.6rem;
            }
            @include respond-to('wide_slider') {
                margin-bottom: -1rem;
                font-size: 2rem;
            }
            &.texto-det {
                color: $primary-blue;
            }
        }
    }
}
