.wrapper {
    min-height: 70vh;
    position: relative;
    a {
        color: $primary-blue;
    }
}
.wrapper-body {
    padding-bottom: 4rem;
    padding-top: 4rem;
    background-color: $primary-grey-light-light;
    min-height: 80vw;
    &.pag-home {
        min-height: 50vw;
    }
    &.azul {
        padding-bottom: 0;
        padding-top: 0;
        background-color: $primary-blue;
    }
    img {
        width: 100%;
        .destacada {
            max-width: 100%;
        }
    }
}
