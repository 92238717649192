// -----------------------------------------------------------------------------
// This file contains all application-wide Sass desktop button.
// -----------------------------------------------------------------------------

.navbar-nav {
    .nav-item {
        margin-bottom:0;
    }
}
.navbar-expand-lg {
    .navbar-nav {
        .nav-link {
            @include respond-to ('old_desktop') {
                padding-right: 0.6rem;
                padding-left: 0.6rem;
            }
        }
    }
}
.nav-tabs {
    .nav-link {
         &:not(.active):hover  {
            border:none;
            position: relative;
            top:1px;
        }
    }
}
ul.nav {
  .collapse {
    @include respond-to ('tablet') {
      padding-left: 0;
    }
    li.dropdown-item {
      a:hover {
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
          outline: none;
          color: $white;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}