// -----------------------------------------------------------------------------
// This file contains all styles related to the preguntas frecuentes of the site/application.
// -----------------------------------------------------------------------------

.preguntas-frecuentes{
    .card {
        border:none;
        background-color: $primary-grey-light-light;
    }
    .div-collapse {
        
    }
    .card-header {
        span {
            width: 95%;
            font-size: 1.3rem;
        }
    }
    .card-body {
        padding: 2rem;
        h2:first-child, h3:first-child, ul:first-child {
            margin-top: 0.5rem;
        }
        ul {
            list-style: none;
            li::before {
                content: "\2022";
                color: $primary-blue;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
    }
}
