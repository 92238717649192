// -----------------------------------------------------------------------------
// This file contains all application-wide Sass desktop promotions.
// -----------------------------------------------------------------------------

.div-promocion{
    margin: 1rem 0 2rem 0;
    font-size: 1.3rem;
    a {
        display: inline-block;
        font-size: 1.3rem;
        &:hover, &:focus, &:visited{
            text-decoration: none;
        }
    }
    img {
        @include border-radius(0.7rem);
        overflow: hidden;
        @extend %box-shadow-desktop;
        &:hover {
            transform:scale(1.01);
        }
    }
    .promocion-fecha {
        display: inherit;
    }
}

div.beneficios {
    .card {
        background-color: $primary-grey-light-light;
        @include border-radius(2rem);
        min-height: 26rem;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        img {
            @include border-radius-partial(2rem, 2rem, 0, 0);
            width: 100%;
            //height: 20rem;
        }
        .card-body {
            padding: 1rem;
            h4 {
                margin-top: 0;
                font-weight: bold;
                font-size: 1.4rem;
                text-align: center;
            }
        }
    }
    hr {
        margin-top: 3rem;
    }
    a {
        //text-decoration: underline;
        color: $primary-blue;
    }
    .solicita-tarjeta {
        img {
            width: 100%;
            @include border-radius(0.7rem);
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        }
        a {
            padding-top: 0.4rem;
            text-decoration: none;
        }
        hr {
            margin-top: 6rem;
        }
    }
    #form_tarjeta {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        //margin: 2rem;
        background-color: $primary-grey-light;
        border-radius: 3rem;
        position: relative;
        top: 0;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        div.form-group {
            margin: 0;
            padding: 0;
        }
        input {
            border-radius: 2rem;
            margin-left: 0.6rem;
        }
        button {
            @include border-radius(2rem);
            //padding-right: 2rem;
            text-align: center;
            font-weight: bold;
            background: $secondary-blue;
            border-color: $primary-blue;
            padding: 0rem 0.5rem 0rem 0rem;
            -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
            -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            //@include box-shadow-2(1rem, 0rem, 1.5rem, $box-shadow, false);
            width: 100%;
            height: 4rem;
            font-size: 1.2rem;
        }
    }
    .tipo-tarjeta {
        .contenedor-card {
            display: table-cell;
            //max-width: 50%;
        }
        div.card {
            min-height: 26rem;
            //width: 80%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                //margin-top: 1rem;
            }
            a.btn {
                position: absolute;
                margin-left: 20%;
                bottom: -4rem;
            }
            .card-body {
                margin-bottom: 1rem;
            }
        }
    }
}

div.promociones {
    &.carrusel-permanentes {
        @mixin aspect-ratio($width, $height) {
            position: relative;

            &:before {
                display: block;
                content: "";
                width: 100%;
                padding-top: ($height / $width) * 100%;
            }

            > img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                &.img-logos {
                    margin-top: 1rem;
                    width: 3rem;
                    height: 3rem;
                    @include respond-to ('tablet') {
                        transform: translateX(40%);
                    }
                    @include respond-to ('old_desktop') {
                        transform: translateX(40%);
                    }
                    @include respond-to ('desktop') {
                        transform: translateX(60%);
                    }
                    @include respond-to ('pre_wide') {
                        transform: translateX(60%);
                    }
                    @include respond-to ('wide') {
                        transform: translateX(70%);
                    }
                    &.verde {
                        display: none;
                    }
                }
            }
        }

        // Styling
        section {
            background: #F4F4F4;
            padding: 50px 0;
        }

        .container {
            max-width: 1044px;
            margin: 0 auto;
            padding: 0 20px;
        }

        .carousel {
            display: block;
            text-align: left;
            position: relative;
            margin-bottom: 22px;
            height: 30rem;

            > input {
                clip: rect(1px, 1px, 1px, 1px);
                clip-path: inset(50%);
                height: 1px;
                width: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;

                &:nth-of-type(7):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -600%; }
                &:nth-of-type(6):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -500%; }
                &:nth-of-type(5):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -400%; }
                &:nth-of-type(4):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -300%; }
                &:nth-of-type(3):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -200%; }
                &:nth-of-type(2):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: -100%; }
                &:nth-of-type(1):checked ~ .carousel__slides .carousel__slide:first-of-type { margin-left: 0%; }

                &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) { box-shadow: 0px 0px 0px 5px $primary-blue; padding-bottom: 1rem;margin: 0 10px;}
                &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) { box-shadow: 0px 0px 0px 5px $primary-blue; padding-bottom: 1rem;margin: 0 10px;}
                &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) { box-shadow: 0px 0px 0px 5px $primary-blue; padding-bottom: 1rem;margin: 0 10px;}
                &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) { box-shadow: 0px 0px 0px 5px $primary-blue; padding-bottom: 1rem;margin: 0 10px;}
                &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) { box-shadow: 0px 0px 0px 5px $primary-blue; padding-bottom: 1rem;margin: 0 10px;}
                &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) { box-shadow: 0px 0px 0px 5px $primary-blue; padding-bottom: 1rem;margin: 0 10px;}
                &:nth-of-type(7):checked ~ .carousel__thumbnails li:nth-of-type(7) { box-shadow: 0px 0px 0px 5px $primary-blue; padding-bottom: 1rem;margin: 0 10px;}

                &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) label img.gris { display: none;}
                &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) label img.verde { display: block;}
                &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) label img.gris { display: none;}
                &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) label img.verde { display: block;}
                &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) label img.gris { display: none;}
                &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) label img.verde { display: block;}
                &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) label img.gris { display: none;}
                &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) label img.verde { display: block;}
                &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) label img.gris { display: none;}
                &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) label img.verde { display: block;}
                &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) label img.gris { display: none;}
                &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) label img.verde { display: block;}
                &:nth-of-type(7):checked ~ .carousel__thumbnails li:nth-of-type(7) label img.gris { display: none;}
                &:nth-of-type(7):checked ~ .carousel__thumbnails li:nth-of-type(7) label img.verde { display: block;}
            }
        }

        .carousel__slides {
            position: relative;
            z-index: 1;
            padding: 0;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            box-sizing: border-box;
            display: flex;
            height: 22rem;
        }

        .carousel__slide {
            position: relative;
            display: block;
            flex: 1 0 100%;
            width: 100%;
            //height: 100%;
            overflow: hidden;
            transition: all 300ms ease-out;
            vertical-align: top;
            box-sizing: border-box;
            white-space: normal;
            height: 22rem;

            figure {
                display: flex;
                margin: 0;
                height: 22rem;
            }

            div {
                @include aspect-ratio(3, 2);
                width: 100%;
            }

            img {
                display: block;
                flex: 1 1 auto;
                object-fit: cover;
            }

            figcaption {
                align-self: flex-end;
                padding: 20px 20px 0 20px;
                flex: 0 0 auto;
                width: 25%;
                min-width: 150px;
                margin-top: auto;
                margin-bottom: auto;
                height: 22rem;
            }

            .credit {
                margin-top: 1rem;
                color: rgba(0, 0, 0, 0.5);
                display: block;
            }

            &.scrollable {
                overflow-y: scroll;
            }
        }

        .carousel__thumbnails {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            margin: 0 -10px;
            height: 7rem;
            @include respond-to ('tablet') {
                width: 100%;
            }
            @include respond-to ('old_desktop') {
                width: 100%;
            }
            @include respond-to ('desktop') {
                width: 80%;
            }
            @include respond-to ('pre_wide') {
                width: 80%;
            }
            @include respond-to ('wide') {
                width: 75%;
            }

            .carousel__slides + & {
                margin-top: 20px;
            }

            li {
                flex: 1 1 auto;
                max-width: calc((100% / 6) - 20px);
                //margin: 0 10px;
                transition: all 300ms ease-in-out;
            }

            label {
                display: block;
                @include aspect-ratio(1,1);
                &:hover,
                &:focus {
                    cursor: pointer;
                    img {
                        box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.25);
                        transition: all 300ms ease-in-out;
                    }
                }
                p.dia-semana {
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    //font-weight: bold;
                    @include respond-to ('tablet') {
                        font-size: 1.2rem;
                        margin-top: -1rem;
                    }
                    @include respond-to ('old_desktop') {
                        font-size: 1.2rem;
                        margin-top: -1rem;
                    }
                    @include respond-to ('pre_wide') {
                        font-size: 1.2rem;
                        margin-top: -2rem;
                    }
                    @include respond-to ('wide') {
                        font-size: 1.4rem;
                        margin-top: -2rem;
                    }
                }
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .img-cadadia {
        height: 10rem;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        @include border-radius(2rem);
    }
    .card {
        background-color: $primary-grey-light-light;
        @include border-radius(2rem);
        min-height: 26rem;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        img {
            @include border-radius-partial(2rem, 2rem, 0, 0);
            width: 100%;
            //height: 18rem;
        }
        .card-body {
            padding: 1rem;
            h4 {
                margin-top: 0;
                font-weight: bold;
                font-size: 1.4rem;
            }
        }
    }
    hr {
        margin-top: 3rem;
    }
    a {
        //text-decoration: underline;
        color: $primary-blue;
    }
    .solicita-tarjeta {
        img {
            width: 100%;
            @include border-radius(0.7rem);
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        }
        a {
            padding-top: 0.4rem;
            text-decoration: none;
        }
        hr {
            margin-top: 6rem;
        }
    }
    #form_tarjeta {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        //margin: 2rem;
        background-color: $primary-grey-light;
        border-radius: 3rem;
        position: relative;
        top: 0;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        div.form-group {
            margin: 0;
            padding: 0;
        }
        input {
            border-radius: 2rem;
            margin-left: 0.6rem;
        }
        button {
            @include border-radius(2rem);
            //padding-right: 2rem;
            text-align: center;
            font-weight: bold;
            background: $secondary-blue;
            border-color: $primary-blue;
            padding: 0rem 0.5rem 0rem 0rem;
            -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
            -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            //@include box-shadow-2(1rem, 0rem, 1.5rem, $box-shadow, false);
            width: 100%;
            height: 4rem;
            font-size: 1.2rem;
        }
    }
    #carousel-otraspromos {
        margin-top: 2rem;
        margin-bottom: 4rem;

        @include centrar-elemento();
        .carousel-inner {
            align-content: center;
            display: flex;
            img {
                @include border-radius(1rem);
                @include box-shadow(0rem, 0.5rem, 1.5rem, $box-shadow);
                width: 85%;
                @include centrar-elemento();
                margin-bottom: 2rem;
            }
        }
        .carousel-control-prev{
            opacity: .75;
            font-size: 2rem;
            svg {
                margin-right: 16rem;
                color: $primary-blue;
            }
        }
        .carousel-control-next{
            opacity: .75;
            font-size: 2rem;
            svg {
                margin-left: 16rem;
                color: $primary-blue;
            }
        }
        ol.carousel-indicators {
            margin: 0;
        }
        .carousel-indicators {
            bottom: -4rem;
            li {
                width: 15px;
                height: 15px;
                border: .5rem solid transparent;
                border-radius: 25px;
                margin: .8rem .1rem;
                background-color: $primary-blue;
                opacity: .5;
                &.active {
                    opacity: .75;
                }
            }
        }
    }
}
