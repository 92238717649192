header {
    background-color: $primary-blue;
    z-index: 1020;
    position: relative;
    .navbar {
        &.categoria-cliente {
            ul {
                width: 100%;
                li.nav-item a {
                    text-align: center;
                    width: 10rem;
                    color: $primary-blue;
                    @include border-top-radius(1rem);
                    font-weight: bold;
                    &.cliente {
                        background-color: $primary-grey-light;
                    }
                    &.comercio {
                        background-color: $secondary-blue;
                        color: $primary-grey-light;
                        margin-left: .3rem;
                    }
                }

            }
        }
        &.menu {
            .barras-menu {
                @include respond-to('<old_desktop') {
                    color:$primary-blue !important;
                }
            }
            background-color: $primary-grey-light;
            margin-top: -1rem;
            @include box-shadow-2(0rem, .8rem, 1rem, $header-shadow, false);
            a.navbar-brand {
                img {
                    position: relative;
                    width: 8rem;
                    z-index: 1050;
                    margin-bottom: -3rem;
                }
            }
            #navbarNav {
                a.nav-link {
                    color: $primary-blue;
                    font-weight:map-get($font-weight,bold);
                    text-shadow: 1px 0 $primary-blue;
                    letter-spacing: 1px;
                    &[href='/autogestion'] {
                        color: $primary-grey-light-light;
                        font-family:$font-type-coolvetica;
                        text-shadow: none;
                        font-weight: normal;
                        @include border-radius(1rem);
                        @include gradient($button-green-1, $button-green-2);
                        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
                        @include respond-to ('<old_desktop'){
                            padding-left: 2rem;
                            margin-top: 1rem;
                        }
                    }
                    &[href='/atencion-clientes'] {
                        font-size: .9rem;
                        width: 70%;
                        text-align: center;
                        margin-top: -0.6rem;
                        padding-bottom: 0;
                        @include respond-to ('<old_desktop'){
                            margin-top: 1rem;
                        }
                    }
                }
            }
            .navbar-collapse {
                @include respond-to('<old_desktop') {
                    background:$primary-grey-light-light;
                    position: absolute;
                    right: 0px;
                    top: 6rem;
                    padding: 1.5rem 2rem 2rem;
                    min-width: 20rem;
                    @include box-shadow(0rem, 0.5rem, 1.5rem, $header-shadow);
                }
            }
        }
        &.azul {
            .barras-menu {
                @include respond-to('<old_desktop') {
                    color:$primary-grey-light !important;
                }
            }
            background-color: $secondary-blue;
            #navbarNav {
                a.nav-link {
                    color: $white;
                    font-weight:map-get($font-weight,bold);
                    text-shadow: 1px 0 $white;
                    letter-spacing: 1px;
                    &[href='/autogestion'] {
                        color: $primary-grey-light-light;
                        font-family:$font-type-coolvetica;
                        text-shadow: none;
                        font-weight: normal;
                        @include border-radius(1rem);
                        @include gradient($button-green-1, $button-green-2);
                        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
                        @include respond-to ('<old_desktop'){
                            padding-left: 2rem;
                            margin-top: 1rem;
                        }
                    }
                }
            }
            .navbar-collapse {
                @include respond-to('<old_desktop') {
                    background:$secondary-blue;
                    position: absolute;
                    right: 0px;
                    top: 6rem;
                    padding: 1.5rem 2rem 2rem;
                    min-width: 20rem;
                    @include box-shadow(0rem, 0.5rem, 1.5rem, $header-shadow);
                }
            }
        }
        .dropdown-menu {
            @include border-radius(.5rem);
            color:$primary-blue;
            background-color: $menu-item-hover;
            @include respond-to ('<old_desktop'){
                .dropdown-item {
                    padding:.5rem 1.5rem;
                    color:$primary-blue;
                    &:hover {
                        background-color: $menu-item-hover;
                        color:$primary-blue;
                    }
                }
            }
            @include respond-to ('old_desktop'){
                margin-top:0rem;
                padding:1rem 0;
                //@include border-radius-partial(0, 0, .25rem, .25rem);
                left:1rem;
                .dropdown-item {
                    padding:.5rem 1.5rem;
                }
            }
            .dropdown-item:hover, .dropdown-item:active {
                @include respond-to ('old_desktop'){
                    color:$primary-blue;
                    background: none;
                }
            }
        }
    }
    &.autogestion {
        height: 50px;
        text-align: left;
        width: 100%;
        top: 0;
        z-index: 1050;
        right: 0;
        left: 0;
        display: block;
        color: $primary-blue;
        position: fixed;
        padding: 0.6rem;
        background-color: $primary-grey-light;
        @include box-shadow(0rem, 0.5rem, 0.5rem, $header-shadow);
        div.row {
            margin-left: 0;
            margin-right: 0;
        }
        .circulo {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: $primary-blue;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 1rem;
            b {
                color: $white;
                font-size: 1.4rem;
                font-weight: 900;
            }
        }
        .columna1 {
            padding-top: 0.2rem;
            //padding-left: 1.6rem;
            .navbar-brand {
                position: absolute;
                z-index: 1050;
                width: 8rem;
            }
        }
        .columna2 {
            margin-top: 0.2rem;
            font-weight: bold;
            font-size: 1.2rem;
            text-align: left;
            .img-home {
                width: 2rem;
                margin-top: 0;
            }
            p {
                color: $text-color;
            }
            a {
                margin-top: 0.2rem;
                font-weight: bold;
                font-size: 1.2rem;
                text-align: left;
                color: $primary-blue;
            }
        }
        .columna4 {
            margin-top: 0.2rem;
            font-weight: bold;
            font-size: 1.2rem;
            text-align: right;
            .img-home {
                width: 2rem;
                margin-top: 0;
            }
        }
        .columna3 {
            text-align: center;
            .img-cerrar {
                width: 1.2rem;
                margin-top: 0.6rem;
            }
        }
        a#campana-alerta {
            display:none;
            color: $primary-blue;
            span {
                position: absolute;
                top: 0.5rem;
            }
        }
    }
}
