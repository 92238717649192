.alert {
    text-align: center;
    margin-bottom: -5rem;
    margin-top: 2rem;
    z-index: 1050;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    &.alert-autogestion {
        margin-top: 0;
    }
}