
// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------



// FOOTER GENERAL ----------------------------------------------------------------------------------------------------------------------------------------------------
.footer-gral{
    position: relative;
    z-index: 1020;
    background-color: $primary-blue;
    height: fit-content;
    @include box-shadow(0.5rem, 0rem, 1.5rem, $header-desktop-shadow);
    //padding: 4rem 1.8rem 4rem 1.8rem;
    a, p, span, h2 {
        color: $white;
        &:hover {
            cursor: pointer;
        }
    }
    svg {
        color: $white;
    }
    a.menu-footer-estatico {
        width: 100%;
        display: inline-block;
    }
    .logo-footer-passcard {
        img {
            margin-top: 4rem;
            width: 10rem;
            display: grid;
            @include respond-to ('<old_desktop') {
                width: 8rem;
            }
            @include respond-to ('old_desktop') {
                width: 8rem;
            }
            @include respond-to ('pre_wide') {
                width: 10rem;
            }
            @include respond-to ('wide_slider') {
                width:10rem;
            }
        }
    }
    .social {
        font-size: 1rem;
    }
    .facebook-icon {
        width: 2.2rem;
        top:-1px;
        margin-right: .5rem;
        margin-left: .5rem;
        filter: invert(1);
        &:hover{
            opacity: 0.6;
            filter: invert(1);
        }
    }
    .linkedin-icon {
        width: 2.2rem;
        opacity: 0.9;
        filter: invert(1);
        margin-right: .5rem;
        &:hover{
            opacity: 0.6;
            filter: invert(1);
        }
    }
    .instagram-icon {
        width: 2.4rem;
        opacity: 0.9;
        &:hover{
            opacity: 0.6;
        }
    }
    .youtube-icon {
        width: 2.2rem;
        opacity: 0.9;
        margin-right: .5rem;
        filter: invert(1);
        flex-wrap: wrap;
        &:hover{
            opacity: 0.6;
            filter: invert(1);
        }
    }
    .mapa-icon {
        width: 1.6rem;
        opacity: 0.9;
    }
    .icono-telefono {
        width: 1.6rem;
        opacity: 0.9;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .bloque-claro {
        background-color: $primary-grey-light;
        p {
            text-align: center;
            font-size: .8rem;
            color: $primary-blue;
            padding: 0;
            margin: 0;
            a {
              color: $primary-blue;
            }
        }
    }
    .footer-contacto {
        width: 5%;
        position: absolute;
        top: 0;
        bottom: 3rem;
        right: 0;
        padding: 0;
        z-index: 1030;
        margin-top: 0.2rem;
        background-color: $primary-green;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        @include border-radius-partial(4rem, 0, 0, 4rem);
        height: 60%;
        @include respond-to ('tablet') {
            bottom: 4rem;
        }
        @include respond-to ('old_desktop') {
            bottom: 3rem;
        }
        @include respond-to ('wide') {
            bottom: 3rem;
        }
        img {
            margin-top: 2rem;
            width: 50%;
            @include respond-to ('tablet') {
                margin-top: 3rem;
            }
            @include respond-to ('old_desktop') {
                margin-top: 2.3rem;
            }
            @include respond-to ('wide') {
                margin-top: 2.3rem;
            }
            @include respond-to ('<old_desktop') {
                width: 60%;
            }
            @include respond-to ('old_desktop') {
                width: 60%;
            }
            @include respond-to ('pre_wide') {
                width: 60%;
            }
            @include respond-to ('wide') {
                width: 50%;
            }
        }
        .row {
            margin: 0;
            .col {
                padding: 0;
            }
        }
    }
    &.solo_copyright {
        position: relative;
        width: 100%;
    }
}
.cursor-pointer{
    cursor: pointer;
}
// MENU FLECHAS COLLAPSE
ul.nav a.nav-link.collapsed svg.fa-angle-down {
    transform: rotate(-90deg);
    @include transition(all,.25s);
}
ul.nav a.nav-link svg.fa-angle-down {
    @include transition(all,.25s);
}
