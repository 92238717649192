.tarjeta-seguros-asistencia{
    h2 {
        font-size: 1.2rem;
    }
    .img-destacada {
        overflow: hidden;
        @include border-radius(0.7rem);
        margin: 1rem auto 1.5rem;

        .destacada {
            @include border-radius(0.7rem);
            margin: 0;
            max-width: 100%;
        }
    }
    button {
        margin-top: 3rem;
    }
    .card {
        width: 80%;
        text-align: center;
        margin-bottom: 4rem;
        background-color: $primary-grey-light;
        @include border-radius(0.7rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        padding: 0;
        img.card-img-top {
            @include border-radius(0.7rem);
        }
    }
}

.seccion-clientes {
    h1.titulo-cliente {
        color: $primary-grey-light;
        font-size: 2rem;
        font-weight: 900;
        padding: 0;
        &.tutoriales {
            color: $primary-blue;
        }
    }
    h2.titulo-cliente {
        color: $primary-grey-light;
        font-size: 1.6rem;
        font-weight: 900;
        &.tutoriales {
            color: $primary-blue;
        }
        &.beneficios {
            position: absolute;
            top: -2rem;
        }
        &.primero {
            left: -7rem;
        }
        &.segundo {
            right: 0;
            margin-right: -7rem;
        }
    }
    h3.titulo-cliente {
        color: $primary-grey-light;
        margin-top: 1rem;
        margin-bottom: 4rem;
        &.tutoriales {
            color: $primary-blue;
            @include respond-to ('tablet') {
                font-size: 1rem;
            }
            @include respond-to ('old_desktop') {
                font-size: 1.2rem;
            }
            @include respond-to ('wide') {
                font-size: 1.4rem;
            }
        }
        &.tutoriales-beneficios {
            color: $primary-blue;
            @include respond-to ('tablet') {
                font-size: 1rem;
            }
            @include respond-to ('old_desktop') {
                font-size: 1.2rem;
            }
            @include respond-to ('wide') {
                font-size: 1.2rem;
            }
        }
        &.devoluciones {
            color: $primary-blue;
            @include respond-to ('tablet') {
                font-size: 1.2rem;
            }
            @include respond-to ('old_desktop') {
                font-size: 1.6rem;
            }
            @include respond-to ('wide') {
                font-size: 2rem;
            }
        }
        &.derecha {
            text-align: right;
        }
        &.izquierda {
            text-align: left;
        }
        &.dev-derecha {
            text-align: right;
            @include respond-to ('tablet') {
                margin-right: -6rem;
            }
            @include respond-to ('<old_desktop') {
                margin-right: -6rem;
            }
            @include respond-to ('old_desktop') {
                margin-right: -8rem;
            }
            @include respond-to ('wide') {
                margin-right: -8rem;
            }
        }
        &.dev-izquierda {
            @include respond-to ('tablet') {
                margin-left: -6rem;
            }
            @include respond-to ('<old_desktop') {
                margin-left: -6rem;
            }
            @include respond-to ('old_desktop') {
                margin-left: -8rem;
            }
            @include respond-to ('wide') {
                margin-left: -8rem;
            }
        }
        &.destacar-letra::first-letter {
            font-size: 2rem;
            font-weight: 900;
            text-shadow: 1px 0 $primary-blue;
            letter-spacing: 10px;
        }
    }
    img {
        &.datos-plantilla {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
    a {
        img {
            @include border-radius(.5rem);
        }
        &.promo {
            margin-top: 3rem;
            display: block;
            text-align: center;
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
        &.vinc-preview {
            position: absolute;
            bottom: 0;
            z-index: 1050;
        }
        &.primero {
            left: 0rem;
            margin-left: -7rem;
        }
        &.segundo {
            right: -7rem;
        }
    }
    .card {
        @include border-radius(.5rem);
        background-color: $white;
        margin-bottom: 4rem;
        min-height: 30rem;
        img {
            @include border-radius(.5rem);
        }
        .card-header {
            min-height: 5rem;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
        }
        .card-body {
            padding-left: 1rem;
            padding-right: 1rem;
            .card-title {
                font-weight: bold;
                font-size: 1.1rem;
                margin-top: 1rem;
            }
            .card-text {
                font-size: .9rem;
            }
        }
        .card-footer {
            span {
                text-align: center;
                a {
                    display: block;
                }
            }
        }
    }
    iframe {
        @include border-radius(0.7rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        &.video-devolucion {
            min-height: 24rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include respond-to ('tablet') {
                width: 80%;
            }
            @include respond-to ('old_desktop') {
                width: 70%;
            }
            @include respond-to ('wide') {
                width: 60%;
            }
        }
    }
    ul {
        color: $primary-blue;
        font-size: 1.2rem;
        li {
            margin-top: .3rem;
        }
    }

    div.centrar-vertical {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .img-cancelacion {
        display: block;
        width: 40%;
        &.derecha {
            margin-left: auto;
            margin-right: 0;
        }
        &.izquierda {
            margin-left: 0;
            margin-right: auto;
        }
    }

    .header-imagen {
        @include border-radius(0.7rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .mensaje-alerta {
        color: #155724;
        background-color: #d4edda;
        text-align: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0.75rem 1.25rem;
        border: 1px solid #c3e6cb;
        border-radius: 0.25rem;
        font-size: 1.6rem;
    }
}

.landing-tarjeta-passcard {
    .header-solicitud {
        width: 100%;
        height: 70rem;
        margin-top: -4rem;
        background: rgba(0, 47, 112, 0.85);
        background: -webkit-linear-gradient(270deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);
        background: linear-gradient(270deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);
        position: absolute;
        z-index: 1000;

        clip-path: polygon(100% 0px, 100% 80%, 17.10% 98.23%, 14.54% 98.6%, 12.91% 98.83%, 11.06% 98.34%, 9.36% 96.83%, 7.99% 94.83%, 6.54% 91.5%, 5.54% 87.33%, 0px 50%, 0px 0px);
    }

    .fondo-form {
        width: 100%;
        height: 74rem;
        position: absolute;
        bottom: 0;
        z-index: 1000;
        background: rgba(0, 47, 112, 0.85);
        background: -webkit-linear-gradient(0deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);
        background: linear-gradient(0deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);

        border-radius: 25% 30% 25% 30% / 5% 5% 0% 0%;
    }

    .container {
        z-index: 1010;
        position: relative;
    }

    .imagen-tarjetas {
        max-width: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    h1 {
        font-size: 2.5rem;
        padding: 0;
        text-align: center;
        margin-top: 0rem;
        color: #fff;
        text-shadow: 1px 0 #fff;
        letter-spacing: 1px;
    }

    span.titulo {
        display: flex;
        justify-content: center;
        font-size: 2.5rem;
        color: #fff;
    }

    span.subtitulo {
        display: flex;
        justify-content: center;
        font-size: 1.6rem;
        color: #fff;
    }

    div.triangulo {
        background-size: cover;
        min-height: 40vh;
        color: white;
        overflow: hidden;
        position: relative;
        border-radius: 2rem;
        z-index: 1010;
        padding: 1rem;
        @include respond-to ('tablet') {
            font-size: 1rem;
        }
        @include respond-to ('<old_desktop') {
            font-size: 1rem;
        }
        @include respond-to ('old_desktop') {
            font-size: 1rem;
        }
        @include respond-to ('wide_slider') {
            font-size: 1.2rem;
        }
    }

    div.punta {
        width: 5rem;
        height: 5rem;
        transform: rotate(270deg);
        clip-path: ellipse(200px 55px at 210px);
        margin-left: auto;
        margin-right: auto;
        margin-top: -3rem;
        z-index: 1000;
        position: relative;
    }

    .caja-color-verde {
        background-color: #58C3B7;
    }

    .caja-color-azulclaro {
        background-color: #4479BD;
    }

    .caja-color-azulfuerte {
        background-color: #1E3264;
    }

    .caja-color-gris {
        background-color: #8c8c8c ;
        //color: #4d4d4d !important;
        color: #fff !important;
    }

    .caja-color-negro {
        background-color: #222121;
        //color: #4d4d4d !important;
        color: #fff !important;
    }

    .caja-color-verdejoven {
        background-color: #05b6a1;
        //color: #4d4d4d !important;
        color: #fff !important;
    }

    .contenedor-columnas {
        display: flex;
        flex-wrap: wrap;
        gap: 10px; /* Espacio entre columnas (opcional) */
    }

    .column {
        flex: 1; /* Hace que cada columna sea igual de ancha */
        min-width: 0; /* Asegura que las columnas se adapten bien en pantallas pequeñas */
        padding: 5px;
        box-sizing: border-box; /* Incluye padding y border en el ancho total del elemento */
    }


    form {
        margin-top: 4rem;
    }

    #formulario-solicitud {
        color: #fff !important;
    }

    #formulario-solicitud a {
        color: #fff !important;
    }

    .form-group input {
        color: #495057;
        background-color: #fff;
    }
}
