.calendario-cierres {
    margin-bottom: 8rem;
    #carousel-cierres {
        ol.carousel-indicators {
            margin: 0;
            bottom: -3rem;
        }
        .carousel-indicators li {
            background-color: $primary-blue;
            width: 10px;
            height: 10px;
            border: .5rem solid transparent;
            border-radius: 25px;
            //margin: .8rem .1rem;
        }
        .carousel-item {
            .card {
                border: none;
                border-radius: 0;
                width: 60%;
                display: block;
                margin: auto;
            }
        }
        a {
            i, svg {
                font-size: 2rem;
                color: $primary-blue;
            }
            &.carousel-control-prev {
                left: -5rem;
            }
            &.carousel-control-next {
                right: -5rem;
            }
        }
    }
}