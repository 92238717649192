// compileOnSave: true
@charset 'UTF-8';
                                          
// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
// 1. Configuration and helpers
@import 'abstracts/all';
// 2. Base stuff
@import 'base/all';
//// 4. Layout-related sections
@import 'layout/desktop/all';
//// 5. Componentss
@import 'components/desktop/all';
//// 6. Page-specific styles
@import 'pages/desktop/all';
