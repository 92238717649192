
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

%a-light {
    color:$menu-item-static;
    @include transition (all, 0.4s, ease-in-out);
    &:hover {
        color:$menu-item-hover;
    }
}

%btn-shadow {
    @include box-shadow(0.5rem, 0.5rem, 1rem, $box-shadow-light-desktop);
    @include transition (all, 0.4s, ease-in-out);
    &:hover {
        @include box-shadow(0.5rem, 0.5rem, 1rem, $box-shadow-desktop);
    }
}
%box-shadow-desktop-light {
    @include box-shadow(0rem, 0.5rem, .8rem, $box-shadow-light);
    @include transition (all, 0.4s, ease-in-out);
    &:hover {
        @include box-shadow(0rem, 0.4rem, 1rem, $box-shadow);
    }
}
%box-shadow-desktop {
    @include box-shadow(0.5rem, 0.5rem, 1.2rem, $box-shadow-light-desktop);
    @include transition (all, 0.4s, ease-in-out);
    &:hover {
        @include box-shadow(0.4rem, 0.4rem, 1.6rem, $box-shadow-desktop);
    }
}
%box-home-shadow-desktop {
    @include box-shadow(1rem, 1rem, 1.8rem, $box-shadow-light-desktop);
    @include transition (all, 0.4s, ease-in-out);
    &:hover {
        @include box-shadow(.9rem, .9rem, 2rem, $box-shadow-desktop);
    }
}